<template>
  <div>
    <el-tooltip
      :content="scope.row.hidden ? 'Вернуть видимость цели' : 'Скрыть цель'"
      placement="top"
      :open-delay="500"
    >
      <el-button
        class="table-action-button"
        size="mini"
        round
        :icon="scope.row.hidden ? 'fas fa-eye-slash' : 'fas fa-eye'"
        @click="toggleHidden"
      ></el-button>
    </el-tooltip>
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";
import requestSender from "@/api/base/requestSender";
export default {
  name: 'element-hide-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {
      scaleTypesEnum: variables.scaleTypesEnum,
    }
  },
  computed: {},
  methods: {
    toggleHidden(){
      requestSender('post', 'card-element/mark-hidden', {
        id: this.scope.row.id,
        hidden: !this.scope.row.hidden,
      })
        .then(() => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Видимость цели успешно обновлена'
          });
          this.$emit('elements-changed');
        })
    },

  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
